import React, { useState, useEffect } from 'react'
import './VisualProgram.css'
import { Link, useHistory } from 'react-router-dom'
import cacheAssets from '../../../utils/cacheAssets'
import packageJson from '../../../../package.json'
import renderPrgImage from '../../../source/programImg'
import VisualPrgm from '../../ReusableComponents/PrgmSlider/VisualPrgm/VisualPrgm'
import MainHeader from '../../ReusableComponents/Header/MainHeader'
import { generateDeviceDataKey } from '../../ReusableComponents/ByteTransfer/utils'
import { connect } from 'react-redux'
import { languageWords } from '../../../utils/langaugeSupport'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'

const blockBaseLink = {
    ACE0: 'https://blockbased.plode.org',
    ACE1: 'https://blockbased.plode.org/?pc1.0',
    HUMANOID0: 'https://blockbased.plode.org/?zing0.1',
    HUMANOID1: 'https://blockbased.plode.org/?zing1.0',
    HUMANOID2: 'https://blockbased.plode.org/?zing1.0s3',
    HEXAPOD1: 'https://blockbased.plode.org/?hexapod',
    HEXAPOD2: 'https://blockbased.plode.org/?hexapod1.0s3',
    ROBOKI1: 'https://blockbased.plode.org/?roboki',
}

function VisualProgram(props) {
    const version = sessionStorage.getItem('deviceVersion')
    const device = sessionStorage.getItem('connectedDevice')
    const languageName = props.device.language
    const [showDialogModal, setShowDialogModal] = useState({
        show: false,
        link: '',
        text1: '',
        text2: '',
        text3: '',
    })
    const [show_tost_text, setShow_tost_text] = useState({
        isShow: false,
        text: '',
    })
    // const [isShow_comingSoon_text, setIsShow_comingSoon_text] = useState(false)
    // const [isShow_cServerDown_text, setIsShow_cServerDown_text] =
    //     useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const item1Styl = {
        backgroundImage: `url("${renderPrgImage(
            'flowchartbasedgroupbutton'
        )}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'all',
        opacity: ['Hexapod', 'Roboki', 'Klaw', 'Atr', 'Toki', 'Moki'].includes(
            device
        )
            ? 0.5
            : 1,

        // cursor: ['Hexapod', 'Roboki'].includes(device) ? 'default' : 'pointer',
    }
    const item2Styl = {
        backgroundImage: `url("${renderPrgImage('projectbased')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    }
    const item3Styl = {
        backgroundImage: `url("${renderPrgImage('blockbased')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        opacity: ['Klaw', 'Atr', 'Toki', 'Moki'].includes(device)
            ? //  ||`${device}` === '0.5'
              0.5
            : 1,
    }
    const item4Styl = {
        backgroundImage: `url("${renderPrgImage('Cgroupbutton')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        /* The above code is setting the value of the CSS `cursor` property based on the conditions
       specified. If the value of the `version` variable is not equal to '1.0.03' or the value of the
       `device` variable is 'Humanoid', then the cursor will be set to 'not-allowed'. Otherwise, the
       cursor will be set to 'pointer'. */
        cursor:
            !version?.startsWith('1') ||
            !localStorage.getItem('accessToken') ||
            ['Humanoid', 'Roboki', 'Klaw', 'Atr', 'Toki', 'Moki'].includes(
                device
            )
                ? 'default'
                : 'pointer',
        // pointerEvents:
        //     !version?.startsWith('1') ||
        //     device === 'Humanoid' ||
        //     device === 'Roboki'
        //         ? 'none'
        //         : 'all',
        opacity:
            [
                'Hexapod',
                'Humanoid',
                'Roboki',
                'Klaw',
                'Atr',
                'Toki',
                'Moki',
            ].includes(device) ||
            !version?.startsWith('1') ||
            !localStorage.getItem('accessToken')
                ? '0.5'
                : '1',
        // cursor: 'pointer',
    }
    const item5Styl = {
        backgroundImage: `url("${renderPrgImage('pythoncodingbutton')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        cursor:
            device === 'Humanoid' ||
            device === 'Roboki' ||
            (device === 'Ace' &&
                (version.startsWith('1.') || version[0] == '0'))
                ? 'pointer'
                : 'default',
        // pointerEvents:
        //     device === 'Humanoid' ||
        //     (device === 'Ace' &&
        //         (version.startsWith('1.') || version[0] == '0'))
        //         ? 'all'
        //         : 'none',

        opacity:
            device === 'Humanoid' ||
            device === 'Roboki' ||
            (device === 'Ace' &&
                (version.startsWith('1.') || version[0] == '0'))
                ? 1
                : 0.5,
    }
    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    //set the dialogModel for microPython firmware present
    const handelShowDialogModel = (link) => {
        setShowDialogModal({
            show: true,
            link: link, //blockBaseLink[blockBaseLinkKey],
            text1: `This feature is not supported for the Micropython firmware.`,
            text2: `If you want to experience the full capabilities of this feature, please upload the latest device specific Plode firmware from `,
            text3: 'Do you still wish to continue?',
        })
    }

    function blockbasedbtn() {
        const microPython = sessionStorage.getItem('microPython')
        if (['Klaw', 'Atr', 'Toki', 'Moki'].includes(device)) {
            setShow_tost_text({
                isShow: true,
                text: ' The feature is not currently available for this device.Coming soon...',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }
        const blockBaseLinkKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersion
        )

        if (microPython == 'true') {
            handelShowDialogModel(blockBaseLink[blockBaseLinkKey])
            return
        }
        sessionStorage.removeItem('slideDirection')

        window.location.assign(blockBaseLink[blockBaseLinkKey])
        // if (device === 'Humanoid') {
        //     if (
        //         ['0.1.1', '0.1.10'].includes(
        //             sessionStorage.getItem('deviceVersion')
        //         ) &&
        //         ['Humanoid'].includes(device)
        //     ) {
        //         window.location.assign('https://blockbased.plode.org/?zing0.1')
        //     } else {
        //         window.location.assign('https://blockbased.plode.org/?zing1.0')
        //     }
        // } else {
        //     // if (['1.0.0', '1.0.03'].includes(version))
        //     if (version[0] === '1')
        //         window.location.assign('https://blockbased.plode.org/?pc1.0')
        //     else window.location.assign('https://blockbased.plode.org')
        // }
    }

    const projectBaseHandelClick = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (microPython == 'true') {
            handelShowDialogModel('/code/project')
            return
        }
        sessionStorage.removeItem('slideDirection')
        sessionStorage.setItem('programMode', 'projectBased')
        window.location.href = '/code/project'
        // history.push('/code/project')
        // window.location.reload()
    }
    const pythonCodeClickHandel = () => {
        let microPython = sessionStorage.getItem('microPython')
        if (!microPython || microPython == 'false') {
            setShowDialogModal({
                show: true,
                link: mycroPythonLink,
                text1: `Python Coding is not supported for the Plode firmware.`,
                text2: `If you want to experience the full capabilities of Python, please upload the device specific Micropython firmware from `,
                text3: 'Do you still wish to continue?',
            })
            return
        }
        sessionStorage.removeItem('slideDirection')
        window.location.assign(mycroPythonLink)
    }

    const handelFlowChartClick = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (
            ['Klaw', 'Roboki', 'Hexapod', 'Atr', 'Toki', 'Moki'].includes(
                connectedDevice
            )
        ) {
            setShow_tost_text({
                isShow: true,
                text: ' The feature is not currently available for this device.Coming soon...',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }
        if (microPython == 'true') {
            handelShowDialogModel('/flow')
            return
        }

        sessionStorage.removeItem('slideDirection')
        sessionStorage.setItem('programMode', 'flowchartBased')
        // history.push('/flow')
        // window.location.reload()
        window.location.href = '/flow'
    }

    const handelCcodingClick = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (
            [
                'Hexapod',
                'Klaw',
                'Humanoid',
                'Roboki',
                'Atr',
                'Toki',
                'Moki',
            ].includes(connectedDevice)
        ) {
            setShow_tost_text({
                isShow: true,
                text: ' The feature is not currently available for this device.Coming soon...',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }

        if (!localStorage.getItem('accessToken')) {
            setShow_tost_text({
                isShow: true,
                text: 'C server is down now.',
            })

            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }

        if (microPython == 'true') {
            handelShowDialogModel('https://edu.plode.org/c')
            return
        }

        if (!localStorage.getItem('accessToken')) {
            setShow_tost_text({
                isShow: true,
                text: 'C server is down now.',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }
        sessionStorage.removeItem('slideDirection')
        window.location.assign('https://edu.plode.org/c')
    }

    const downloadSoftware = () => {
        sessionStorage.removeItem('slideDirection')
        const download = setTimeout(() => {
            if (
                JSON.parse(sessionStorage.getItem('isWindowOnFocus')) === true
            ) {
                const a = document.createElement('a')
                a.href = 'https://downloads.plode.org/zingFirmwareTool.exe'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                clearTimeout(download)
            }
        }, 500)
    }

    const history = useHistory()

    // Caching Internal Components assets

    useEffect(() => {
        let assetsToBeCached = [
            renderPrgImage('newfilegroupbutton'),
            renderPrgImage('yourprojectsgroupbutton'),
            renderPrgImage('newactiongroupbutton'),
            renderPrgImage('youractiongroupbutton'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const handlePythonPopup = () => {
        let ele = document.getElementById('popup-text')
        ele.className = 'show'
        console.log('python click', ele)
        setTimeout(function () {
            ele.className = ele.className.replace('show', '')
        }, 3000)
    }

    let mycroPythonLink =
        sessionStorage.getItem('connectedDevice') === 'Ace' &&
        version?.startsWith('1')
            ? 'https://edu.plode.org/python/?device=peecee'
            : sessionStorage.getItem('connectedDevice') === 'Humanoid'
            ? 'https://edu.plode.org/python/?device=zing'
            : 'https://edu.plode.org/python/?device=roboki'

    //in open the file, dialog model shows option generat
    const dialogModelOption = () => {
        let optionArr = []

        optionArr.push({
            text: 'YES',
            do: () => {
                sessionStorage.removeItem('slideDirection')

                if (showDialogModal.link == '/code/project') {
                    history.push(showDialogModal.link)
                    sessionStorage.setItem('programMode', 'projectBased')
                    window.location.reload()
                } else if (showDialogModal.link == '/flow') {
                    history.push(showDialogModal.link)
                    sessionStorage.setItem('programMode', 'flowchartBased')
                    window.location.reload()
                } else {
                    window.location.assign(showDialogModal.link)
                }
            },
        })

        optionArr.push({
            text: 'NO',
            do: () => {
                setShowDialogModal({
                    show: false,
                    link: '',
                    text1: '',
                    text2: '',
                    text3: '',
                })
            },
        })
        return optionArr
    }
    return (
        <>
            <div className="visualProgram-container">
                {/* <img
                    src={renderPrgImage('backBtn')}
                    className="iconBtnSize VP-backbtn"
                    onClick={() => {
                        history.push('/Selection')
                    }}
                />
                {isHelp == false ? (
                    <img
                        className="iconBtnSize helpiconBtnSize"
                        src={renderPrgImage('helpBtnInActive')}
                        style={{ marginRight: '25%' }}
                        onClick={handleHelpBtn}
                    ></img>
                ) : (
                    <div className="S_slide">
                        {' '}
                        <VisualPrgm />{' '}
                    </div>
                )}
                {isHelp ? (
                    <img
                        className="helpClose"
                        src={renderPrgImage('closBtn')}
                        onClick={handleHelpBtn}
                    ></img>
                ) : null}

                <img
                    src={renderPrgImage('programmenucard')}
                    className="VP-programmenucard"
                /> */}
                <DialogModal
                    show={showDialogModal.show}
                    text={
                        <>
                            <p>{showDialogModal.text1}</p>
                            <p>
                                {showDialogModal.text2}
                                <a
                                    href="https://firmware.plode.org"
                                    target="_blank"
                                >
                                    {'here.'}
                                </a>
                            </p>

                            <p>{showDialogModal.text3}</p>
                        </>
                    }
                    showCloseBtn={false}
                    optionsToSelect={dialogModelOption()}
                />
                <MainHeader
                    title={languageWords[languageName]?.Code || 'Code'}
                    titlePosition="left"
                    help_btnPosition="right"
                    helper={<VisualPrgm />}
                    isGoBack={false}
                    goBack={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/selection')
                    }}
                />
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    } `}
                    style={{ position: 'relative' }}
                >
                    <p className="VP-txt-Heading">
                        {languageWords[languageName]?.VisualProgramming ||
                            'Visual Programming'}
                    </p>

                    <hr className="VP-hr" />

                    <p className="VP-txt-Heading2">
                        {languageWords[languageName]?.ScriptProgramming ||
                            'Script Programming'}
                    </p>

                    <hr className="VP-hr2" />
                    <div>
                        {/* ITEM - 1 */}

                        {/* <Link to="/flow"> */}
                        <div
                            className="VP-flowchartbased vp-item1"
                            style={item1Styl}
                            onClick={handelFlowChartClick}
                        >
                            <div
                                className="VP-sub1"
                                style={{
                                    cursor: [
                                        'Hexapod',
                                        'Roboki',
                                        'Klaw',
                                        'Atr',
                                        'Toki',
                                        'Moki',
                                    ].includes(device)
                                        ? 'default'
                                        : 'pointer',
                                }}
                            >
                                <p className="VP-sub-txt">
                                    {languageWords[languageName]
                                        ?.FlowchartBased || 'Flowchart \nBased'}
                                </p>
                            </div>
                        </div>
                        {/* </Link> */}

                        {/* ITEM - 2 */}
                        <div
                            // to="/code/project"

                            onClick={() => {
                                projectBaseHandelClick()
                            }}
                        >
                            <div
                                className="VP-programbased vp-item2"
                                style={item2Styl}
                            >
                                <div
                                    className="VP-sub2"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <p className="VP-sub2-txt">
                                        {languageWords[languageName]
                                            ?.ProjectBased || 'Project \nBased'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Item 3*/}

                        <div
                            className="VP-blockbased vp-item3"
                            style={item3Styl}
                            onClick={() => {
                                blockbasedbtn()
                            }}
                        >
                            <div
                                className="VP-sub3"
                                style={{
                                    cursor: [
                                        'Klaw',
                                        'Atr',
                                        'Toki',
                                        'Moki',
                                    ].includes(device)
                                        ? 'default'
                                        : 'pointer',
                                }}
                            >
                                <p className="VP-sub3-txt">
                                    {languageWords[languageName]?.BlockBased ||
                                        'Block \nBased'}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            backgroundColor: '#8ACDEA',
                            opacity: '10%',
                        }}
                    ></div>
                    {/* Item 4 */}
                    <div
                        className="VP-CCoding vp-item4"
                        style={item4Styl}
                        onClick={handelCcodingClick}
                    >
                        <div className="VP-sub4">
                            <p className="VP-sub4-txt">
                                {languageWords[languageName]?.CCoding ||
                                    'C \nCoding'}
                            </p>
                        </div>
                    </div>

                    {/*Item 5 */}
                    <>
                        {['Humanoid', 'Roboki'].includes(device) ||
                        (device === 'Ace' && version?.startsWith('1')) ? (
                            <a
                                href
                                onClick={() => pythonCodeClickHandel()}
                                target="_self"
                            >
                                <div
                                    className="VP-pythoncodingbutton vp-item5"
                                    style={item5Styl}
                                >
                                    <div className="VP-sub5">
                                        <p className="VP-sub5-txt">
                                            {languageWords[languageName]
                                                ?.PythonCoding ||
                                                'Python \nCoding'}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        ) : (
                            <div
                                className="VP-pythoncodingbutton vp-item5"
                                style={item5Styl}
                                onClick={() => {
                                    if (
                                        [
                                            'Hexapod',
                                            'Klaw',

                                            'Atr',
                                            'Toki',
                                            'Moki',
                                        ].includes(connectedDevice)
                                    ) {
                                        setShow_tost_text({
                                            isShow: true,
                                            text: ' The feature is not currently available for this device.Coming soon...',
                                        })
                                        setTimeout(
                                            () =>
                                                setShow_tost_text({
                                                    isShow: false,
                                                    text: '',
                                                }),
                                            2000
                                        )
                                        return
                                    }

                                    sessionStorage.removeItem('slideDirection')
                                    window.location.assign(
                                        'http://micropython.plode.org'
                                    )

                                    // if (
                                    //     !['Hexapod', 'Klaw'].includes(
                                    //         connectedDevice
                                    //     )
                                    // ) {
                                    //     sessionStorage.removeItem(
                                    //         'slideDirection'
                                    //     )
                                    //     window.location.assign(
                                    //         'http://micropython.plode.org'
                                    //     )
                                    // }
                                }}
                            >
                                <div
                                    className="VP-sub5"
                                    style={{
                                        cursor:
                                            device === 'Humanoid' ||
                                            (device === 'Ace' &&
                                                (version.startsWith('1.') ||
                                                    version[0] == '0'))
                                                ? 'pointer'
                                                : 'default',
                                    }}
                                >
                                    <p className="VP-sub5-txt">
                                        Python <br />
                                        Coding
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>

            {show_tost_text.isShow && (
                <p className="comingSoon_text">{show_tost_text.text}</p>
            )}
            {/* {isShow_cServerDown_text && (
                <p className="comingSoon_text" style={{ left: '45%' }}>
                    C server is down now.
                </p>
            )} */}

            {/* <div id="popup-text">
                update the PeeCee firmware to 1.0.04 version to enable python
                coding
            </div> */}
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(VisualProgram)
